import request from '../utils/request'
import config from './config'

// 服务机构列表
export function organPage(data) {
	return request({
		url: `${config.CIVIL_HOST}/civilCount/servicePage`,
		method: 'post',
		data,
	})
}

// 服务机构--人员列表
export function hksStaffPage(params) {
	return request({
		url: `${config.CIVIL_HOST}/civilCount/hksStaffPage`,
		method: 'get',
		params,
	})
}
